import { injectable, inject } from "inversify";
import { TableWidget } from "../../../../skupno/src/ts/widgets/table-widget";
import { TranslationService } from "../../ts/translation-service";
import { UrlHelper } from "../../../../skupno/src/ts/utils/url-helper";
import * as Mustache from "mustache";
import { RevisionApiClient } from "../../ts/clients/revision-api-client";
import template from "./revision-list.view.html";
import { Action, Domain, parseEnum } from "../../ts/models/revision/revision-enums";
import * as moment from "moment";
import * as DateRangePicker from 'daterangepicker';


@injectable()
export class RevisionListView {
    private _apiClient: RevisionApiClient;
    private itemsPerPage = 25;
    private _tableWidget: TableWidget = null!;



    public constructor(
        @inject(RevisionApiClient) ApiClient: RevisionApiClient,
        @inject(TranslationService) private translationService: TranslationService,
    ) {
        this._apiClient = ApiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<any> {

        try {
              
            var translations: any = await this.translationService.currentTranslations


            this._renderData();
            this._initDatePicker();
            $('#main').find("form[name=revision-filters]").on("submit",  (ev) => {
                 this.onFilterFormSubmit(ev);
           
            });;
           
            var rootElement = $("#main").find(".revision-table-placeholder");

           this._tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }

                    var filter = this.SerelizeSearchForm();
                    filter['Page'] = currentPage;
                    filter['Limit'] = this.itemsPerPage;

                    var data = await this._apiClient.getList(filter, translations);  
                    return data
                },
             
               
            });


           this._tableWidget.refresh(1, this.translationService.currentTranslations);
                
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async onFilterFormSubmit(ev: any): Promise<void> {
        ev.preventDefault();
    
        this._tableWidget.refresh(1, this.translationService.currentTranslations);
    }

    private _renderData(): void {

    
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            translations: this.translationService.currentTranslations,
            actions: parseEnum(Action).map(action => ({
                value: action.value,
                label: this.translationService.currentTranslations[action.label] || action.label
            })),
            
            // Map domains directly if only value is needed
            domains: parseEnum(Domain).map(domain => ({
                value: domain.value,
                label: this.translationService.currentTranslations[domain.label] || domain.label
            }))
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private _initDatePicker(): void {

        const todayStart = moment().startOf('day'); 
        const endOfMonthEnd = moment().endOf('day'); 
        new DateRangePicker($('#main').find("#dateFromTo")[0], 
            {
                autoUpdateInput: false, 
                autoApply: true,
                startDate: todayStart,
                endDate: endOfMonthEnd,
                timePicker: true,
                timePicker24Hour: true, // Enable 24-hour format
                locale: {
                    format: 'DD.MM.YYYY HH:mm',
                    applyLabel: 'Potrdi',
                    cancelLabel: 'Prekliči',
                    fromLabel: 'Od',
                    toLabel: 'Do',
                    weekLabel: 'T',
                    customRangeLabel: 'Po meri',
                }
            });

        $("#dateFromTo").val(todayStart.format("DD.MM.YYYY HH:mm") + " - " + endOfMonthEnd.format("DD.MM.YYYY HH:mm"));
        $("#startDateInput").val(todayStart.toISOString());
        $("#endDateInput").val(endOfMonthEnd.toISOString());

       $('#dateFromTo').on('apply.daterangepicker', (_ev, picker) =>{
            const dateFrom = picker.startDate.toISOString();
            const dateTo =  picker.endDate.toISOString();
           $("#dateFromTo").val(picker.startDate.format("DD.MM.YYYY HH:mm") + " - " + picker.endDate.format("DD.MM.YYYY HH:mm"));

           $("#startDateInput").val(dateFrom);
           $("#endDateInput").val(dateTo);
       });

     

    }


    private dataTableModel(translations: any): any {
        const tableMap = {
            title: translations["Revision"],
            classInsert: "revision-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["EventTime"],
                    value: "createdTime"
                },
                {
                    name: translations["User"],
                    html: `<div>
                                {{createdUserFirstName}} {{createdUserLastName}} 
                            </div>`
                },
                {
                    name: translations["Domain"],
                    value: "domain"
                
                },  
                {
                    name: translations["Object"],
                    value:"domainData"
                },
                {
                    name: translations["EventType"],
                    value: "action"
                },
                {
                    name: "",
                    html: `<a href="#/revizija/pogled/{{id}}"  data-action="edit" data-id="{{id}}"  id="edit-premission-type-modal-btn">
                                                <img src="../img/memo-circle-info-regular.svg" class="img-fluid td-icon" />
                                            </a>`
                },
            ]
        };
        return tableMap;
    }


    private SerelizeSearchForm()
    {
        const formData = $('form[name=revision-filters]').serializeArray();
        const filters: any = {};
    
        // Serialize form data to create filter object
        formData.forEach(item => {
            if (item.value) {
                filters[item.name] = item.value;
            }
        });

        return filters;
    }
}