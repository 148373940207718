import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Revision, parseRevision, parseRevisions } from "../models/revision/revision";
import { TranslationService } from "../translation-service";

@injectable()
export class RevisionApiClient extends ApiClientBase {
    public name = "PermissionApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(data:any, translations: TranslationService): Promise<ListResponse<Revision>> {
        
        var response  = await this._callApi('/revision/search', 'GET', data) as ListResponse<Revision>;
        var parseData = await parseRevisions(response, translations);

        return parseData;
    }

    public async get(id: number, translations: TranslationService): Promise<Revision> {

        var data  = await this._callApi(`/revision/${id}`, 'GET') as Revision;
        var revision =  await parseRevision(data, translations);
        return revision;
    }

} 